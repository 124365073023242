<script lang="ts" setup>
import dayjs from 'dayjs';
import checkScrollbarWidth from '~/utils/scrollbarWidth';

dayjs.locale('ru');
checkScrollbarWidth();

const siteStore = useSiteStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const catalogStore = useCatalogStore();
const profileStore = useProfileStore();
const route = useRoute();

authStore.isLogged = false;

await profileStore.getUserInfo();

authStore.isLogged = profileStore.userDetails !== null;

await Promise.any([
  authStore.getAccessRights(),
  siteStore.getSiteSettings(),
  cartStore.getCart(),
  catalogStore.getFavorites(),
]);

const { isShowAboveHeaderBlock } = storeToRefs(siteStore);
const { accessRights } = storeToRefs(authStore);

const { width } = useBreakpoints();

const isShowHeader = ref(true);
const lastScrollPosition = ref(0);
const scrollOffset = ref(50);

watch(
  () => width.value,
  () => {
    if (width.value >= 1024) {
      isShowHeader.value = true;
    }
  },
);

function onScroll() {
  if (
    window.scrollY < 0 ||
    window.innerWidth > 1024 ||
    siteStore.isMobileMenuOpen ||
    Math.abs(window.scrollY - lastScrollPosition.value) < scrollOffset.value
  ) {
    return;
  }
  isShowHeader.value = window.scrollY < lastScrollPosition.value;
  lastScrollPosition.value = window.scrollY;
}

function copyListener(event: ClipboardEvent) {
  const range = document.getSelection()?.getRangeAt(0),
    rangeContents = range?.cloneContents(),
    pageLink = `Узнайте больше о трюковых самокатах на ${document.location.href}`,
    helper = document.createElement('div');

  if (rangeContents) helper.appendChild(rangeContents);

  if (helper.innerText.length > 100) {
    event.clipboardData?.setData('text/plain', `${helper.innerText}\n${pageLink}`);
    event.clipboardData?.setData('text/html', `${helper.innerHTML}<br><br>${pageLink}`);
    event.preventDefault();
  }
}

useHead({
  script: [
    {
      type: 'text/javascript',
      innerHTML: `(function(w, d, s, h, id) {
          w.roistatProjectId = id; w.roistatHost = h;
          var p = d.location.protocol == "https:" ? "https://" : "http://";
          var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
          var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
        })(window, document, 'script', 'cloud.roistat.com', '866adbbb3b248c4ca9af453e81e9be35')`,
      tagPosition: 'bodyOpen',
    },
  ],
});

onMounted(() => {
  lastScrollPosition.value = window.scrollY;
  document.addEventListener('scroll', onScroll);
  document.addEventListener('copy', copyListener);
});

onBeforeUnmount(() => {
  document.removeEventListener('scroll', onScroll);
  document.removeEventListener('copy', copyListener);
});
</script>

<template>
  <div
    :class="{ 'with-top-bar': isShowAboveHeaderBlock }"
    class="default-layout"
  >
    <AdminPanelBlock v-if="accessRights?.info_panel" />

    <TheHeader
      :class="{ 'is-hidden': !isShowHeader }"
      class="header"
    />

    <main class="page__wrapper">
      <div
        :class="{ 'no-padding': String(route.name).includes('profile') }"
        class="page__content"
      >
        <slot />
      </div>
    </main>

    <TheFooter />

    <MobileBottomNavigation />
  </div>
</template>

<style lang="scss" scoped>
.default-layout {
  $top-bar: 28px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  padding-top: calc(76px - $top-bar);

  &.with-top-bar {
    padding-top: 76px;
  }

  @include screen-md {
    padding-top: calc(85px - $top-bar);

    &.with-top-bar {
      padding-top: 85px;
    }
  }

  @include screen-lg {
    padding-top: calc(138px - $top-bar);

    &.with-top-bar {
      padding-top: 138px;
    }
  }
}

.header {
  transition: transform 200ms linear;
  transform: translateY(0);
}

.header.is-hidden {
  transform: translateY(-100%);
}

.page {
  &__wrapper {
    @include inner-wrapper;

    flex-grow: 1;
    background-color: $hell-black-5;
  }

  &__content {
    width: 100%;
    padding: 24px 0 48px;

    &.no-padding {
      padding-top: 0;
    }
  }
}
</style>
